// import { v4 as uuidv4 } from 'uuid'

export const VERSION = '0.1.1'
export const PROJECT_NAME = 'QR Code'
export const API_URL = 'https://qr.xstudios.com/api/v1'

export const SIDENAV = [
  {
    to: {
      name: 'Dashboard',
    },
    icon: 'mdi-view-dashboard',
    title: 'Dashboard',
    meta: {
      permissions: ['spaday.view_dashboard'],
    },
  },
  {
    divider: true,
  },
  // {
  //     header: 'Auth',
  // },
  {
    title: 'Users',
    icon: 'mdi-account',
    to: {
      name: 'User',
    },
    chip: false,
    meta: {
      permissions: ['users.view_user', 'users.add_user', 'users.change_user', 'users.delete_user'],
    },
  },
  {
    title: 'Groups',
    icon: 'mdi-account-group',
    to: {
      name: 'Group',
    },
    chip: false,
    meta: {
      permissions: ['auth.view_group', 'auth.add_group', 'auth.change_group', 'auth.delete_group'],
    },
  },
  {
    title: 'Audit Log',
    icon: 'mdi-clipboard-text-clock-outline',
    to: {
      name: 'AuditLog',
    },
    chip: false,
    meta: {
      permissions: [
        'auditlog.view_logentry',
        'auditlog.add_logentry',
        'auditlog.change_logentry',
        'auditlog.delete_logentry',
      ],
    },
  },
  {
    title: 'Celery Task Results',
    icon: 'mdi-calendar-check',
    to: {
      name: 'TaskResult',
    },
    chip: false,
    meta: {
      permissions: [
        'django_celery_results.view_taskresult',
        'django_celery_results.add_taskresult',
        'django_celery_results.change_taskresult',
        'django_celery_results.delete_taskresult',
      ],
    },
  },
  //   {
  //     icon: 'mdi-account-group',
  //     active: true,
  //     items: [
  //       { title: 'Users', to: { name: 'User' }, chip: true },
  //       { title: 'Groups', to: { name: 'Group' }, chip: true },
  //     ],
  //     title: 'Auth',
  //   },
  {
    divider: true,
  },
  //   {
  //     header: 'Models',
  //   },
  {
    icon: 'mdi-office-building',
    title: 'Companies',
    to: {
      name: 'Company',
    },
    meta: {
      permissions: ['core.view_company', 'core.add_company', 'core.change_company', 'core.delete_company'],
    },
  },
  {
    icon: 'mdi-calendar-multiple',
    title: 'Events',
    to: {
      name: 'Event',
    },
    meta: {
      permissions: ['core.view_event', 'core.add_event', 'core.change_event', 'core.delete_event'],
    },
  },
  {
    icon: 'mdi-qrcode',
    title: 'QR Codes',
    to: {
      name: 'QRCode',
    },
    meta: {
      permissions: ['core.view_qrcode', 'core.add_qrcode', 'core.change_qrcode', 'core.delete_qrcode'],
    },
  },
  {
    icon: 'mdi-qrcode-scan',
    title: 'Scans',
    to: {
      name: 'Scan',
    },
    meta: {
      permissions: ['core.view_scan', 'core.add_scan', 'core.change_scan', 'core.delete_scan'],
    },
  },
]
