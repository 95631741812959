import { getDeleteMessage, getChangeMessage, getAddMessage } from '@/spaday/helpers'
import { CompanyService } from '@/services'

const state = {
  all: {},
  object: {},
  delete: {},
  combobox: {},
  events: {},
  qrcodes: {},
}

const MODEL_NAME = 'company'

const actions = {
  getAll({ commit }, params) {
    commit('GET_ALL_REQUEST')

    return CompanyService.getAll(params).then(
      (data) => {
        commit('GET_ALL_SUCCESS', data)
        return data
      },
      (error) => {
        commit('GET_ALL_FAILURE', error)
      },
    )
  },

  get({ commit }, id) {
    commit('GET_REQUEST')

    return CompanyService.get(id).then(
      (data) => {
        commit('GET_SUCCESS', data)
      },
      (error) => {
        commit('GET_FAILURE', error)
      },
    )
  },

  create({ dispatch, commit }, data) {
    commit('CREATE_REQUEST')

    return CompanyService.create(data).then(
      (data) => {
        commit('CREATE_SUCCESS', data)
        // show toast dialog
        let ctx = {
          text: getAddMessage(MODEL_NAME, `${data.name}`),
          color: 'success',
        }
        dispatch('toastDialog/show', ctx, { root: true })
      },
      (error) => {
        commit('CREATE_FAILURE', error)
      },
    )
  },

  update({ dispatch, commit }, { id, data }) {
    commit('UPDATE_REQUEST')

    return CompanyService.update(id, data).then(
      (data) => {
        commit('UPDATE_SUCCESS', data)
        // show toast dialog
        let ctx = {
          text: getChangeMessage(MODEL_NAME, `${data.name}`),
          color: 'success',
        }
        dispatch('toastDialog/show', ctx, { root: true })
      },
      (error) => {
        commit('UPDATE_FAILURE', error)
      },
    )
  },

  preDelete({ dispatch, commit }, object) {
    commit('PRE_DELETE_REQUEST')

    return CompanyService.preDelete(object.pk).then(
      (data) => {
        commit('PRE_DELETE_SUCCESS')
        // show delete confirmation dialog
        data.pk = object.pk
        data.name = `${object.name}`
        dispatch('deleteDialog/confirm', data, { root: true })
      },
      (error) => {
        commit('PRE_DELETE_FAILURE', error)
      },
    )
  },

  delete({ dispatch, commit }, object) {
    commit('DELETE_REQUEST')

    return CompanyService.delete(object.pk).then(
      () => {
        commit('DELETE_SUCCESS', object.pk)
        // show toast dialog
        let ctx = {
          text: getDeleteMessage(MODEL_NAME, `${object.name}`),
          color: 'success',
        }
        dispatch('toastDialog/show', ctx, { root: true })
      },
      (error) => {
        commit('DELETE_FAILURE', error)
      },
    )
  },

  clear({ commit }) {
    commit('CLEAR')
  },

  getCombobox({ commit }) {
    commit('GET_COMBOBOX_REQUEST')

    return CompanyService.combobox().then(
      (data) => commit('GET_COMBOBOX_SUCCESS', data),
      (error) => commit('GET_COMBOBOX_FAILURE', error),
    )
  },

  getEvents({ commit }, id) {
    commit('GET_EVENTS_REQUEST')

    return CompanyService.events(id).then(
      (data) => commit('GET_EVENTS_SUCCESS', data),
      (error) => commit('GET_EVENTS_FAILURE', error),
    )
  },

  getQRCodes({ commit }, id) {
    commit('GET_QRCODES_REQUEST')

    return CompanyService.qrcodes(id).then(
      (data) => commit('GET_QRCODES_SUCCESS', data),
      (error) => commit('GET_QRCODES_FAILURE', error),
    )
  },
}

const mutations = {
  GET_ALL_REQUEST(state) {
    state.all = {
      loading: true,
    }
  },

  GET_ALL_SUCCESS(state, data) {
    state.all = {
      items: data.results,
    }
  },

  GET_ALL_FAILURE(state, error) {
    state.all = {
      error,
    }
  },

  GET_REQUEST(state) {
    state.object = {
      loading: true,
    }
  },

  GET_SUCCESS(state, data) {
    state.object = data
  },

  GET_FAILURE(state, error) {
    state.object = {
      error,
    }
  },

  CREATE_REQUEST(state) {
    state.object = {
      loading: true,
    }
  },

  CREATE_SUCCESS(state, data) {
    state.object = data
  },

  CREATE_FAILURE(state, error) {
    state.object = {
      error,
    }
  },

  UPDATE_REQUEST(state) {
    state.object = {
      loading: true,
    }
  },

  UPDATE_SUCCESS(state, data) {
    state.object = data
  },

  UPDATE_FAILURE(state, error) {
    state.object = {
      error,
    }
  },

  CLEAR(state) {
    state.object = {}
  },

  PRE_DELETE_REQUEST(state) {
    state.delete = {
      loading: true,
    }
  },

  PRE_DELETE_SUCCESS(state) {
    state.delete = {}
  },

  PRE_DELETE_FAILURE(state, error) {
    state.delete = {
      error,
    }
  },

  DELETE_REQUEST(state) {
    state.delete = {
      loading: true,
    }
  },

  DELETE_SUCCESS(state, id) {
    state.delete = {}

    // remove deleted object from state
    if (state.all.items) {
      state.all.items = state.all.items.filter((object) => object.pk !== id)
    }
  },

  DELETE_FAILURE(state, error) {
    state.delete = {
      error,
    }
  },

  /** Combobox **/
  GET_COMBOBOX_REQUEST(state) {
    state.combobox = {
      loading: true,
    }
  },
  GET_COMBOBOX_SUCCESS(state, data) {
    state.combobox = {
      items: data,
    }
  },
  GET_COMBOBOX_FAILURE(state, error) {
    state.combobox = {
      error,
    }
  },

  /** Company Events **/
  GET_EVENTS_REQUEST(state) {
    state.events = {
      loading: true,
    }
  },

  GET_EVENTS_SUCCESS(state, data) {
    state.events = {
      items: data,
    }
  },

  GET_EVENTS_FAILURE(state, error) {
    state.events = {
      error,
    }
  },

  /** Company QR Codes **/
  GET_QRCODES_REQUEST(state) {
    state.qrcodes = {
      loading: true,
    }
  },

  GET_QRCODES_SUCCESS(state, data) {
    state.qrcodes = {
      items: data,
    }
  },

  GET_QRCODES_FAILURE(state, error) {
    state.qrcodes = {
      error,
    }
  },
}

export const companies = {
  namespaced: true,
  state,
  actions,
  mutations,
}
