<template>
  <div>
    <ListHeader
      title="Groups"
      subtitle="Create and manage groups."
      btnLabel="New Group"
      :route="{ name: 'AddGroup' }"
      :canAdd="hasPermission('auth.add_group')"
    />

    <v-card>
      <v-card-title class="text--primary">
        <SearchField label="Search Groups..." @search="updateSearch" />
      </v-card-title>
      <v-data-table
        v-model="groupTable.selected"
        item-key="pk"
        loading-text="Loading. Please wait..."
        :loading="groups.loading"
        :items="groups.items"
        :headers="groupTable.headers"
        :search="groupTable.search"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          'show-current-page': true,
        }"
        :options.sync="groupTable.options"
        :server-items-length="groupTable.totalItems"
        @update:options="getPaginatedGroups"
      >
        <template v-slot:item.actions="{ item }">
          <RowActions :actions="groupTable.actions" @edit="editGroup(item)" @confirm-delete="preDeleteGroup(item)" />
        </template>
      </v-data-table>
    </v-card>

    <DeleteConfirmation @confirm-delete="deleteGroup" />
  </div>
</template>

<script>
import { localDatetime, hasPermission, getPaginationParams } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { ListHeader, RowActions, DeleteConfirmation, SearchField } from '@/spaday/components'

export default {
  components: {
    RowActions,
    DeleteConfirmation,
    ListHeader,
    SearchField,
  },
  data: () => ({
    groupTable: {
      options: {},
      totalItems: 0,
      search: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Users', value: 'num_users', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actions: [
        {
          text: 'Edit Group',
          icon: 'mdi-pencil',
          color: 'primary',
          permissions: ['auth.change_group'],
          emit: 'edit',
        },
        {
          text: 'Delete Group',
          icon: 'mdi-trash-can-outline',
          color: 'error',
          permissions: ['auth.delete_group'],
          emit: 'confirm-delete',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      groups: (state) => state.groups.all,
    }),
  },
  mounted() {
    // this.getGroups()
  },
  methods: {
    hasPermission,
    localDatetime,
    getPaginationParams,
    ...mapActions('groups', {
      getGroups: 'getAll',
      preDeleteGroup: 'preDelete',
      deleteGroup: 'delete',
    }),
    editGroup(item) {
      this.$router.push({ name: 'ChangeGroup', params: { pk: item.pk } })
    },

    /** Pagination handlers **/
    getPaginatedGroups() {
      const params = this.getPaginationParams(this.groupTable.options, this.groupTable.search)
      this.getGroups(params).then((data) => {
        this.groupTable.totalPages = data.num_pages
        this.groupTable.totalItems = data.num_results
      })
    },
    updateSearch(value) {
      this.groupTable.search = value
      this.getPaginatedGroups()
    },
  },
}
</script>
