<template>
  <div>
    <v-card class="mb-4" color="transparent">
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col>
            <v-card-title class="text--primary">Event Stats</v-card-title>
          </v-col>
          <v-col class="text-right">
            <v-btn class="ma-2" small fab depressed icon outlined @click="$router.go(-1)"
              ><v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <v-btn @click="exportPDF()" color="info" x-large><v-icon left>mdi-file-download</v-icon>Export PDF </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-text>
        <v-row align="center">
          <v-col>
            <div><strong>Company:</strong> {{ object.company_name }}</div>
            <div><strong>Event:</strong> {{ object.name }}</div>
            <div><strong>Created:</strong> {{ localDatetime(object.created_at) }}</div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col v-if="stats.scan_data">
            <v-row align="center" justify="center">
              <v-col class="text-center"
                ><div class="text-h6 text--secondary">Total Scans</div>
                <div class="text-h2 text--primary">
                  <strong>{{ stats.scan_data.total_scans }}</strong>
                </div></v-col
              >
              <v-col class="text-center"
                ><div class="text-h6 text--secondary">Unique Scans</div>
                <div class="text-h2 text--primary">
                  <strong>{{ stats.scan_data.unique_scans }}</strong>
                </div></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-toolbar dark flat dense color="primary">
        <!-- <v-icon left></v-icon> -->
        <v-toolbar-title>Number of QR Code Scans</v-toolbar-title>
      </v-toolbar>
      <!-- <v-card-title>Number of QR Code Scans</v-card-title> -->
      <v-card-text>
        <GenericChart v-if="chartData" refs="printMe" :chartdata="chartData" />
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12" md="6" class="d-flex">
        <StatTableCard title="By Operating System" :items="stats.overall.os_data" :headers="tableHeaders" />
      </v-col>

      <v-col cols="12" md="6" class="d-flex">
        <StatTableCard title="By Platform" :items="stats.overall.platform_data" :headers="tableHeaders" />
      </v-col>

      <v-col cols="12" md="6" class="d-flex">
        <StatTableCard title="By Browser" :items="stats.overall.browser_data" :headers="tableHeaders" />
      </v-col>

      <v-col cols="12" md="6" class="d-flex">
        <StatTableCard title="By Device" :items="stats.overall.device_data" :headers="tableHeaders" />
      </v-col>

      <v-col cols="12" md="4" class="d-flex">
        <StatTableCard title="By City" :items="stats.overall.city_data" :headers="tableHeaders" />
      </v-col>

      <v-col cols="12" md="4" class="d-flex">
        <StatTableCard title="By State" :items="stats.overall.state_data" :headers="tableHeaders" />
      </v-col>

      <v-col cols="12" md="4" class="d-flex">
        <StatTableCard title="By Country" :items="stats.overall.country_data" :headers="tableHeaders" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { localDatetime } from '@/spaday/helpers'
// import { getRandomColor } from '@/helpers/colors'
import { mapState, mapActions } from 'vuex'
import { StatTableCard, GenericChart } from '@/spaday/components'

// js/html2canvas/html2canvas.min.js
// js/pdfmake/pdfmake.min.js

export default {
  components: {
    StatTableCard,
    // StatCard,
    GenericChart,
  },
  data: () => ({
    tableHeaders: [
      { text: 'Name', value: 'label', align: 'left' },
      { text: 'Scans', value: 'value', align: 'left' },
      { text: 'Percentage', value: 'percentage', align: 'left' },
    ],
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      object: (state) => state.events.object,
      stats: (state) => state.events.stats,
    }),

    chartData() {
      //   if (this.stats.overall.chart_data.length === 0) {
      //     console.log('empty')
      //     return {}
      //   }
      //   console.log('populated')

      //   let chart = {
      //     type: 'line',
      //     options: {
      //       // aspectRatio: 4 / 1,
      //       legend: {
      //         display: true,
      //       },
      //       scales: {
      //         yAxes: [
      //           {
      //             ticks: {
      //               beginAtZero: true,
      //             },
      //           },
      //         ],
      //       },
      //     },
      //   }

      //   if (this.stats.datasets.length >= 1) {
      //     console.log(this.stats.overall.chart_data)
      //     let datasets = [
      //       {
      //         label: 'Overall',
      //         data: this.stats.overall.chart_data.values,
      //         barPercentage: 0.5,
      //         // backgroundColor: 'rgba(105, 103,206,.5)',
      //         borderColor: 'rgba(105, 103,206,.5)',
      //         borderWidth: 5,
      //         fill: false,
      //       },
      //     ]
      //     // this.stats.datasets.forEach(function (item) {
      //     //   datasets.push({
      //     //     label: item.label,
      //     //     data: item.data,
      //     //     barPercentage: 0.5,
      //     //     // backgroundColor: 'rgba(105, 103,206,.5)',
      //     //     borderColor: getRandomColor(),
      //     //     borderWidth: 2,
      //     //     fill: false,
      //     //   })
      //     // })
      //     chart.data = {
      //       labels: this.stats.overall.chart_data.labels,
      //       datasets: datasets,
      //     }
      //   }

      //   return chart

      return {
        type: 'line',
        data: {
          labels: this.stats.overall.chart_data.labels,
          datasets: [
            {
              label: 'Scans',
              data: this.stats.overall.chart_data.values,
              barPercentage: 0.5,
              backgroundColor: 'rgba(105, 103,206,.5)',
              borderColor: 'rgb(105, 103,206)',
              borderWidth: 2,
              fill: false,
            },
            //   {
            //     label: 'Alligator',
            //     data: this.stats.datasets[0].data,
            //     barPercentage: 0.5,
            //     // backgroundColor: 'rgba(105, 103,206,.5)',
            //     borderColor: this.getRandomColor(),
            //     borderWidth: 2,
            //     fill: false,
            //   },
          ],
        },
        options: {
          aspectRatio: 4 / 1,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      }
    },
  },
  mounted() {
    this.getEvent(this.$route.params.pk)
    this.getStats(this.$route.params.pk)
  },
  methods: {
    // hasPermission,
    localDatetime,
    ...mapActions('events', {
      getEvent: 'get',
      getStats: 'getStats',
    }),
    submit() {
      if (!this.$refs.form.validate()) return
    },
    exportPDF() {
      EventBus.$emit('show-generic-dialog', {
        title: 'Oops!',
        message:
          'This feature is not implemented yet. <i>We are still working on it...</i> <img width="100%" src="https://media.giphy.com/media/fQZX2aoRC1Tqw/giphy.gif" class="mt-4"/>',
        color: 'warning',
        icon: 'mdi-alert',
      })

      // html2canvas(this.$refs.printMe, {
      //   onrendered: function (canvas) {
      //     var data = canvas.toDataURL();
      //     var docDefinition = {
      //       content: [{
      //         image: data,
      //         width: 500,
      //       }]
      //     };

      //     var pdfMake = require('pdfmake/build/pdfmake.js')
      //     pdfMake.createPdf(docDefinition).download("qr_code_details.pdf");

      //   }
      // });

      //   var pdfMake = require('pdfmake/build/pdfmake.js')
      //   if (pdfMake.vfs == undefined) {
      //     var pdfFonts = require('pdfmake/build/vfs_fonts.js')
      //     pdfMake.vfs = pdfFonts.pdfMake.vfs
      //   }
      //   var docDefinition = { content: 'This is an sample PDF printed with pdfMake' }
      //   pdfMake.createPdf(docDefinition).download('optionalName.pdf')
    },
  },
}
</script>
