import http from '@/spaday/http-common'

class QRCodeService {
  /** Base CRUD REST calls **/
  getAll(params = {}) {
    return http.get('/qrcodes/', { params })
  }

  get(pk) {
    return http.get(`/qrcodes/${pk}/`)
  }

  create(data) {
    return http.post('/qrcodes/', data)
  }

  update(pk, data) {
    return http.put(`/qrcodes/${pk}/`, data)
  }

  preDelete(pk) {
    return http.get(`/qrcodes/${pk}/confirm-delete/`)
  }

  delete(pk) {
    return http.delete(`/qrcodes/${pk}/`)
  }

  /** Specialized REST calls **/

  stats(pk) {
    return http.get(`/qrcodes/${pk}/stats/`)
  }
}

export default new QRCodeService()
