import Vue from 'vue'
import Vuex from 'vuex'

import { VERSION, PROJECT_NAME } from '@/constants'

import {
  alert,
  deleteDialog,
  toastDialog,
  account,
  users,
  groups,
  permissions,
  uploadProgress,
  auditlog,
  taskresults,
} from '@/spaday/store'

// custom modules
import { preferences } from './preferences.module'
import { companies } from './companies.module'
import { events } from './events.module'
import { qrcodes } from './qrcodes.module'
import { scans } from './scans.module'

import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'

const logrocketPlugin = createPlugin(LogRocket)

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alert,
    deleteDialog,
    toastDialog,
    account,
    users,
    groups,
    permissions,
    uploadProgress,
    auditlog,
    taskresults,
    // custom modules
    preferences,
    companies,
    events,
    qrcodes,
    scans,
    plugins: [logrocketPlugin],
  },
  state: {
    projectName: PROJECT_NAME,
    version: VERSION,
  },
  mutations: {},
})
