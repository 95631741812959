<template>
  <div>
    <FormHeader
      title="Create Event"
      :subtitle="object.name"
      btnLabel="Save Event"
      :canAdd="hasPermission('core.add_event')"
      @submit="submit"
      :saveLoading="$store.state.events.object.loading"
    />

    <v-form ref="form">
      <v-card class="mb-4">
        <v-toolbar dark flat dense color="primary">
          <v-icon left>mdi-information-variant</v-icon>
          <v-toolbar-title> General Info </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="object.name" label="Name" :rules="[rules.required]"></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                label="Company"
                v-model="object.company"
                :items="companies.items"
                :loading="companies.loading"
                item-value="pk"
                item-text="name"
                hide-no-data
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-file-input
                v-model="object.logo"
                :rules="[rules.value]"
                show-size
                small-chips
                prepend-icon="mdi-image"
                accept="image/png, image/jpeg, image/jpg"
                label="Logo"
              >
              </v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { hasPermission } from '@/spaday/helpers'
import { mapActions, mapState } from 'vuex'
import { FormHeader } from '@/spaday/components'

export default {
  components: { FormHeader },
  data: () => ({
    object: {},
    rules: {
      required: (value) => !!value || 'Required.',

      value: (value) => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      companies: (state) => state.companies.combobox,
    }),
  },
  mounted() {
    this.getCompanies()

    // auto select company
    if (this.$route.query.company) {
      this.object.company = parseInt(this.$route.query.company)
    }
  },
  methods: {
    hasPermission,
    ...mapActions('events', {
      createEvent: 'create',
    }),
    ...mapActions('companies', {
      getCompanies: 'getCombobox',
    }),
    submit() {
      if (!this.$refs.form.validate()) return

      this.createEvent(this.object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
