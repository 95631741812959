<template>
  <div>
    <ListHeader title="Scans" subtitle="Create and manage scans." />

    <v-card>
      <v-card-title class="text--primary">
        <SearchField label="Search Scans..." @search="updateSearch" />
      </v-card-title>
      <v-data-table
        v-model="scanTable.selected"
        :show-select="false"
        item-key="pk"
        loading-text="Loading. Please wait..."
        :loading="scans.loading"
        :items="scans.items"
        :headers="scanTable.headers"
        :search="scanTable.search"
        :footer-props="{
          'items-per-page-options': [15, 25, 50, 100],
          'show-current-page': true,
        }"
        :options.sync="scanTable.options"
        :server-items-length="scanTable.totalItems"
        @update:options="getPaginatedScans"
      >
        <template v-slot:item.browser="{ item }">
          {{ item.browser_version }}
        </template>
        <template v-slot:item.os="{ item }">
          {{ item.os_version }}
        </template>
        <template v-slot:item.device="{ item }">
          {{ item.device_family }}
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ localDatetime(item.created_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <RowActions :actions="scanTable.actions" @edit="editScan(item)" @confirm-delete="preDeleteScan(item)" />
        </template>
      </v-data-table>
    </v-card>

    <DeleteConfirmation @confirm-delete="deleteScan" />
  </div>
</template>

<script>
import { localDatetime, hasPermission, hasAnyPermission, getPaginationParams } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { DeleteConfirmation, ListHeader, RowActions, SearchField } from '@/spaday/components'

export default {
  components: {
    RowActions,
    DeleteConfirmation,
    ListHeader,
    SearchField,
  },
  data: () => ({
    scanTable: {
      options: {},
      totalItems: 0,
      search: '',
      selected: [],
      headers: [
        { text: 'IP Address', value: 'ip_address' },
        { text: 'Location', value: 'location', sortable: false },
        { text: 'QR Code', value: 'qr_code' },
        { text: 'Browser', value: 'browser', sortable: false  },
        { text: 'OS', value: 'os', sortable: false  },
        { text: 'Device', value: 'device', sortable: false },
        { text: 'Platform', value: 'platform', sortable: false },
        { text: 'Created', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actions: [
        {
          text: 'View Scan',
          icon: 'mdi-eye',
          color: 'primary',
          permissions: ['core.view_scan'],
          emit: 'edit',
        },
        {
          text: 'Delete Scan',
          icon: 'mdi-trash-can',
          color: 'error',
          permissions: ['core.delete_scan'],
          emit: 'confirm-delete',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      scans: (state) => state.scans.all,
    }),
  },
  mounted() {
    // this.getScans()
  },
  methods: {
    hasPermission,
    hasAnyPermission,
    localDatetime,
    getPaginationParams,
    ...mapActions('scans', {
      getScans: 'getAll',
      preDeleteScan: 'preDelete',
      deleteScan: 'delete',
    }),
    editScan(item) {
      this.$router.push({ name: 'ViewScan', params: { pk: item.pk } })
    },

    /** Pagination handlers **/
    getPaginatedScans() {
      const params = this.getPaginationParams(this.scanTable.options, this.scanTable.search)
      this.getScans(params).then((data) => {
        this.scanTable.totalPages = data.num_pages
        this.scanTable.totalItems = data.num_results
      })
    },
    updateSearch(value) {
      this.scanTable.search = value
      this.getPaginatedScans()
    },
  },
}
</script>
