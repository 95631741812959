import { List, Update, Create, Stats } from '@/views/qrcode'

const routes = [
  {
    path: '/qrcode',
    name: 'QRCode',
    component: List,
    meta: {
      public: false,
      permissions: ['core.view_qrcode'],
    },
  },
  {
    path: '/qrcode/add',
    name: 'AddQRCode',
    component: Create,
    meta: {
      public: false,
      permissions: ['core.add_qrcode'],
    },
  },
  {
    path: '/qrcode/:pk/change',
    name: 'ChangeQRCode',
    component: Update,
    meta: {
      public: false,
      permissions: ['core.change_qrcode'],
    },
  },
  {
    path: '/qrcode/:pk/stats',
    name: 'StatsQRCode',
    component: Stats,
    meta: {
      public: false,
      permissions: ['core.view_qrcode'],
    },
  },
]

export default routes

// export default routes.map((route) => {
//   const meta = {
//     public: false,
//   }
//   return {
//     ...route,
//     meta,
//   }
// })
