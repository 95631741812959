import { List, Update, Create, Stats } from '@/views/event'

const routes = [
  {
    path: '/event',
    name: 'Event',
    component: List,
    meta: {
      public: false,
      permissions: ['core.view_event'],
    },
  },
  {
    path: '/event/add',
    name: 'AddEvent',
    component: Create,
    meta: {
      public: false,
      permissions: ['core.add_event'],
    },
  },
  {
    path: '/event/:pk/change',
    name: 'ChangeEvent',
    component: Update,
    meta: {
      public: false,
      permissions: ['core.change_event'],
    },
  },
  {
    path: '/event/:pk/stats',
    name: 'StatsEvent',
    component: Stats,
    meta: {
      public: false,
      permissions: ['core.view_event'],
    },
  },
]

export default routes
