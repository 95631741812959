<template>
  <div>
    <FormHeader
      title="Create QR Code"
      :subtitle="object.name"
      btnLabel="Save QR Code"
      :canAdd="hasPermission('core.add_qrcode')"
      @submit="submit"
      :saveLoading="$store.state.qrcodes.object.loading"
    />

    <v-form ref="form">
      <v-card class="mb-4">
        <v-toolbar dark flat dense color="primary">
          <v-icon left>mdi-information-variant</v-icon>
          <v-toolbar-title> General Info </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="object.name" label="Name" :rules="[rules.required]"></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field v-model="object.redirect_url" label="Redirect URL" :rules="[rules.required]"></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                label="Event"
                v-model="object.event"
                :items="events.items"
                :loading="events.loading"
                item-value="pk"
                item-text="name"
                hide-no-data
              ></v-select>
            </v-col>

            <!-- <v-col cols="12" md="6">
              <v-file-input
                v-model="object.logo"
                :rules="[rules.value]"
                show-size
                small-chips
                prepend-icon="mdi-image"
                accept="image/png, image/jpeg, image/jpg"
                label="Logo"
              ></v-file-input
            ></v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { hasPermission } from '@/spaday/helpers'
import { FormHeader } from '@/spaday/components'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    FormHeader,
  },
  data: () => ({
    object: {},
    rules: {
      required: (value) => !!value || 'Required.',

      value: (value) => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      events: (state) => state.events.combobox,
    }),
  },
  mounted() {
    this.getEvents()
    // if (this.$route.query.company) {
    //   this.getCompanyEvents(parseInt(this.$route.query.company))
    // } else {
    //   this.getEvents()
    // }
  },
  methods: {
    hasPermission,
    ...mapActions('qrcodes', {
      createQRCode: 'create',
    }),
    ...mapActions('events', {
      getEvents: 'getCombobox',
    }),
    // ...mapActions('companies', {
    //   getCompanyEvents: 'getEvents',
    // }),
    submit() {
      if (!this.$refs.form.validate()) return

      // Submmitting object as form data to allow multipart/form-data
      let formData = new FormData()
      Object.keys(this.object).forEach((key) => {
        if (this.object[key] !== null) {
          formData.append(key, this.object[key])
        }
      })

      this.createQRCode(this.object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
