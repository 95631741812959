import http from '@/spaday/http-common'

class EventService {
  /** Base CRUD REST calls **/
  getAll(params = {}) {
    return http.get('/events/', { params })
  }

  get(pk) {
    return http.get(`/events/${pk}/`)
  }

  create(data) {
    return http.post('/events/', data)
  }

  update(pk, data) {
    return http.put(`/events/${pk}/`, data)
  }

  preDelete(pk) {
    return http.get(`/events/${pk}/confirm-delete/`)
  }

  delete(pk) {
    return http.delete(`/events/${pk}/`)
  }

  /** Specialized REST calls **/

  combobox() {
    return http.get(`/events/combobox/`)
  }

  qrcodes(pk) {
    return http.get(`/events/${pk}/qrcodes/`)
  }

  stats(pk) {
    return http.get(`/events/${pk}/stats/`)
  }
}

export default new EventService()
