<template>
  <div>
    <FormHeader
      title="Update Event"
      :subtitle="object.name"
      btnLabel="Update Event"
      :canChange="hasPermission('core.change_event')"
      :canDelete="hasPermission('core.delete_event')"
      @submit="submit"
      @delete="preDeleteEvent(object)"
      :deleteLoading="$store.state.events.delete.loading"
      :saveLoading="$store.state.events.object.loading"
    />

    <v-form ref="form" :readonly="!hasPermission('core.change_event')">
      <v-card class="mb-4" :loading="object.loading">
        <v-tabs v-model="tab" grow icons-and-text slider-size="4">
          <v-tabs-slider></v-tabs-slider>
          <template v-for="(item, index) in tabs">
            <v-tab :key="index" :href="item.href">
              {{ item.text }}
              <v-icon>{{ item.icon }}</v-icon>
            </v-tab>
          </template>
        </v-tabs>

        <v-divider class="primary"></v-divider>

        <v-tabs-items v-model="tab">
          <v-tab-item value="general">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="object.name" label="Name" :rules="[rules.required]"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    label="Company"
                    v-model="object.company"
                    :items="companies.items"
                    :loading="companies.loading"
                    item-value="pk"
                    item-text="name"
                    hide-no-data
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-file-input
                    v-model="object.logo"
                    :rules="[rules.value]"
                    show-size
                    small-chips
                    prepend-icon="mdi-image"
                    accept="image/png, image/jpeg, image/jpg"
                    label="Logo"
                  ></v-file-input
                ></v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>

          <v-tab-item value="codes">
            <QRCodeList :event="object" />
          </v-tab-item>
        </v-tabs-items>

        <v-divider></v-divider>
        <v-card-actions
          ><v-spacer></v-spacer>
          <div class="caption">
            Event ID <strong>{{ object.pk }}</strong>
          </div></v-card-actions
        >
      </v-card>
    </v-form>

    <DeleteConfirmation @confirm-delete="onConfirmDeleteEvent" />
  </div>
</template>

<script>
import { localDatetime, hasPermission } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { FormHeader, DeleteConfirmation } from '@/spaday/components'
import QRCodeList from '@/views/event/components/QRCodeList'

export default {
  components: {
    DeleteConfirmation,
    FormHeader,
    QRCodeList,
  },
  data: () => ({
    tabs: [
      {
        text: 'General Info',
        icon: 'mdi-information',
        // permissions: ['users.change_user'],
        href: '#general',
      },
      {
        text: 'QR Codes',
        icon: 'mdi-map-marker-multiple',
        // permissions: ['users.change_user'],
        href: '#codes',
      },
    ],
    rules: {
      required: (value) => !!value || 'Required.',

      value: (value) => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      object: (state) => state.events.object,
      // qrcodes: (state) => state.events.qrcodes,
      companies: (state) => state.companies.combobox,
    }),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        return this.$route.query.tab
      },
    },
  },
  mounted() {
    this.getEvent(this.$route.params.pk)
    // this.getQRCodes(this.$route.params.pk)
    this.getCompanies()
  },
  beforeDestroy() {
    this.clearEvent()
  },
  methods: {
    hasPermission,
    localDatetime,
    ...mapActions('qrcodes', {
      preDeleteQRCode: 'preDelete',
      deleteQRCode: 'delete',
    }),
    ...mapActions('events', {
      getEvent: 'get',
      updateEvent: 'update',
      preDeleteEvent: 'preDelete',
      deleteEvent: 'delete',
      clearEvent: 'clear',
      getQRCodes: 'getQRCodes',
    }),
    ...mapActions('companies', {
      getCompanies: 'getCombobox',
    }),
    submit() {
      if (!this.$refs.form.validate()) return

      // Submmitting object as form data to allow multipart/form-data
      let formData = new FormData()
      Object.keys(this.object).forEach((key) => {
        if (this.object[key] !== null) {
          formData.append(key, this.object[key])
        }
      })

      this.updateEvent({ id: this.object.pk, data: this.object }).then(() => {
        this.$router.go(-1)
      })
    },

    onConfirmDeleteEvent(object) {
      this.deleteEvent(object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
