/**
 * The vue router defines all of the routes for the application, and contains
 * a function that runs before each route change to prevent unauthenticated
 * users from accessing restricted routes.
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '@/store/'

import { auth_routes, user_routes, group_routes, auditlog_routes, taskresult_routes } from '@/spaday/router'
import { PageNotFound, AdminBase } from '@/spaday/views'

import company_routes from '@/router/company.js'
import event_routes from '@/router/event.js'
import qrcode_routes from '@/router/qrcode.js'
import scan_routes from '@/router/scan.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'Dashboard' },
    component: AdminBase,
    children: [
      {
        path: '/dash',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
    ]
      .concat(user_routes)
      .concat(group_routes)
      .concat(auditlog_routes)
      .concat(taskresult_routes)
      .concat(company_routes)
      .concat(event_routes)
      .concat(qrcode_routes)
      .concat(scan_routes),
    meta: {
      public: false,
    },
  },
  // and finally the default route, when none of the above matches
  { path: '*', component: PageNotFound },
]

const router = new VueRouter({
  mode: 'history',
  routes: routes.concat(auth_routes),
  base: '/admin/',
})

router.beforeEach((to, from, next) => {
  const authenticated = sessionStorage.getItem('loggedIn')
  const onlyLoggedOut = to.matched.some((record) => record.meta.onlyLoggedOut)
  const isPublic = to.matched.some((record) => record.meta.public)
  if (!isPublic && !authenticated) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return next({
      name: 'Login',
      query: { redirect: to.fullPath },
    })
  }
  if (authenticated && onlyLoggedOut) {
    return next({ name: 'Dashboard' })
  }
  next()
})

export default router
