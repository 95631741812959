import Vuetify from '@/plugins/vuetify'

const state = {
  darkMode: true,
}

const actions = {
  initialiseStore({ commit }) {
    let darkMode = localStorage.getItem('darkMode') === 'false' ? false : true
    commit('SET_DARK_MODE', darkMode)
  },

  toggleDarkMode({ commit }) {
    commit('TOGGLE_DARK_MODE')
  },

  setDarkMode({ commit }, val) {
    commit('SET_DARK_MODE', val)
  },
}

const mutations = {
  TOGGLE_DARK_MODE(state) {
    state.darkMode = !state.darkMode
    Vuetify.framework.theme.dark = state.darkMode
    localStorage.setItem('darkMode', state.darkMode)
  },
  SET_DARK_MODE(state, val) {
    Vuetify.framework.theme.dark = val
    state.darkMode = val
    localStorage.setItem('darkMode', val)
  },
}

export const preferences = {
  namespaced: true,
  state,
  actions,
  mutations,
}
