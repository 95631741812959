<template>
  <v-icon small color="success" v-if="bool === true">mdi-check-circle</v-icon>
  <v-icon small color="error" v-else>mdi-close-circle</v-icon>
</template>

<script>
export default {
  props: {
    bool: { type: Boolean },
  },
}
</script>
