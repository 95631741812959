<template>
  <div>
    <ListHeader
      title="Events"
      subtitle="Create and manage events."
      btnLabel="New Event"
      :route="{ name: 'AddEvent' }"
      :canAdd="hasPermission('core.add_event')"
    />

    <v-card>
      <v-card-title class="text--primary">
        <SearchField label="Search Events..." @search="updateSearch" />
      </v-card-title>
      <v-data-table
        v-model="eventTable.selected"
        :show-select="false"
        item-key="pk"
        loading-text="Loading. Please wait..."
        :loading="events.loading"
        :items="events.items"
        :headers="eventTable.headers"
        :search="eventTable.search"
        :footer-props="{
          'items-per-page-options': [15, 25, 50, 100],
          'show-current-page': true,
        }"
        :options.sync="eventTable.options"
        :server-items-length="eventTable.totalItems"
        @update:options="getPaginatedEvents"
      >
        <template v-slot:item.company="{ item }">
          {{ item.company_name }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ localDatetime(item.created_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <RowActions
            :actions="eventTable.actions"
            @edit="editEvent(item)"
            @confirm-delete="preDeleteEvent(item)"
            @view-stats="viewStats(item)"
          />
        </template>
      </v-data-table>
    </v-card>

    <DeleteConfirmation @confirm-delete="deleteEvent" />
  </div>
</template>

<script>
import { localDatetime, hasPermission, getPaginationParams } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { DeleteConfirmation, ListHeader, RowActions, SearchField } from '@/spaday/components'

export default {
  components: {
    RowActions,
    DeleteConfirmation,
    ListHeader,
    SearchField,
  },
  data: () => ({
    eventTable: {
      options: {},
      totalItems: 0,
      search: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'QR Codes', value: 'total_codes', sortable: false },
        { text: 'Company', value: 'company' },
        { text: 'Created', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actions: [
        {
          text: 'Edit Event',
          icon: 'mdi-pencil',
          color: 'primary',
          permissions: ['core.change_event'],
          emit: 'edit',
        },
        {
          text: 'Delete Event',
          icon: 'mdi-trash-can',
          color: 'error',
          permissions: ['core.delete_event'],
          emit: 'confirm-delete',
        },
        {
          divider: true,
        },
        {
          text: 'View Stats',
          icon: 'mdi-chart-bar',
          color: 'success',
          permissions: ['core.view_event'],
          emit: 'view-stats',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      events: (state) => state.events.all,
    }),
  },
  mounted() {
    // this.getEvents()
  },
  methods: {
    hasPermission,
    localDatetime,
    getPaginationParams,
    ...mapActions('events', {
      getEvents: 'getAll',
      preDeleteEvent: 'preDelete',
      deleteEvent: 'delete',
    }),
    editEvent(item) {
      this.$router.push({ name: 'ChangeEvent', params: { pk: item.pk } })
    },
    viewStats(item) {
      this.$router.push({ name: 'StatsEvent', params: { pk: item.pk } })
    },

    /** Pagination handlers **/
    getPaginatedEvents() {
      const params = this.getPaginationParams(this.eventTable.options, this.eventTable.search)
      this.getEvents(params).then((data) => {
        this.eventTable.totalPages = data.num_pages
        this.eventTable.totalItems = data.num_results
      })
    },
    updateSearch(value) {
      this.eventTable.search = value
      this.getPaginatedEvents()
    },
  },
}
</script>
