import { List, Update, Create } from '@/views/company'

const routes = [
  {
    path: '/company',
    name: 'Company',
    component: List,
    meta: {
      public: false,
      permissions: ['core.view_company'],
    },
  },
  {
    path: '/company/add',
    name: 'AddCompany',
    component: Create,
    meta: {
      public: false,
      permissions: ['core.add_company'],
    },
  },
  {
    path: '/company/:pk/change',
    name: 'ChangeCompany',
    component: Update,
    meta: {
      public: false,
      permissions: ['core.change_company'],
    },
  },
]

export default routes
