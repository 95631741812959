<template>
  <div>
    <FormHeader
      title="Create Company"
      :subtitle="object.name"
      btnLabel="Save Company"
      :canAdd="hasPermission('core.add_company')"
      @submit="submit"
      :saveLoading="$store.state.companies.object.loading"
    />

    <v-form ref="form">
      <v-card class="mb-4">
        <v-tabs v-model="tab" grow icons-and-text slider-size="4">
          <v-tabs-slider></v-tabs-slider>
          <template v-for="(item, index) in tabs">
            <v-tab :key="index" :href="item.href">
              {{ item.text }}
              <v-icon>{{ item.icon }}</v-icon>
            </v-tab>
          </template>
        </v-tabs>

        <v-divider class="primary"></v-divider>

        <v-tabs-items v-model="tab">
          <v-tab-item value="general">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="object.name" label="Name" :rules="[rules.required]"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-file-input
                    v-model="object.logo"
                    :rules="[rules.value]"
                    show-size
                    small-chips
                    prepend-icon="mdi-image"
                    accept="image/png, image/jpeg, image/jpg"
                    label="Logo"
                  ></v-file-input
                ></v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>

          <v-tab-item value="brand">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="3" sm="6">
                  <label>Primary Color</label>
                  <v-color-picker v-model="object.primary_color" dot-size="15" mode="hexa"></v-color-picker>
                </v-col>

                <v-col cols="12" md="3" sm="6">
                  <label>Secondary Color</label>
                  <v-color-picker v-model="object.secondary_color" dot-size="15" mode="hexa"></v-color-picker>
                </v-col>

                <v-col cols="12" md="3" sm="6">
                  <label>Primary Button Color</label>
                  <v-color-picker v-model="object.primary_btn_color" dot-size="15" mode="hexa"></v-color-picker>
                </v-col>

                <v-col cols="12" md="3" sm="6">
                  <label>Secondary Button Color</label>
                  <v-color-picker v-model="object.secondary_btn_color" dot-size="15" mode="hexa"></v-color-picker>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { hasPermission } from '@/spaday/helpers'
import { FormHeader } from '@/spaday/components'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    FormHeader,
  },
  data: () => ({
    object: {},
    tabs: [
      {
        text: 'General Info',
        icon: 'mdi-information',
        // permissions: ['users.change_user'],
        href: '#general',
      },
      {
        text: 'Brand Colors',
        icon: 'mdi-information',
        // permissions: ['users.change_user'],
        href: '#brand',
      },
    ],
    rules: {
      required: (value) => !!value || 'Required.',
      value: (value) => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        return this.$route.query.tab
      },
    },
  },
  methods: {
    hasPermission,
    ...mapActions('companies', {
      createCompany: 'create',
    }),
    submit() {
      if (!this.$refs.form.validate()) return

      // Submmitting object as form data to allow multipart/form-data
      let formData = new FormData()
      Object.keys(this.object).forEach((key) => {
        if (this.object[key] !== null) {
          formData.append(key, this.object[key])
        }
      })

      this.createCompany(this.object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
