<template>
  <div>
    <FormHeader
      title="Update Company"
      :subtitle="object.name"
      btnLabel="Update Company"
      :canChange="hasPermission('core.change_company')"
      :canDelete="hasPermission('core.delete_company')"
      @submit="submit"
      @delete="preDeleteCompany(object)"
      :deleteLoading="$store.state.companies.delete.loading"
      :saveLoading="$store.state.companies.object.loading"
    />

    <v-form ref="form" :readonly="!hasPermission('core.change_company')">
      <v-card class="mb-4">
        <v-tabs v-model="tab" grow icons-and-text slider-size="4">
          <v-tabs-slider></v-tabs-slider>
          <template v-for="(item, index) in tabs">
            <v-tab :key="index" :href="item.href">
              {{ item.text }}
              <v-icon>{{ item.icon }}</v-icon>
            </v-tab>
          </template>
        </v-tabs>

        <v-divider class="primary"></v-divider>

        <v-tabs-items v-model="tab">
          <v-tab-item value="general">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="object.name" label="Name" :rules="[rules.required]"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-file-input
                    v-model="object.logo"
                    :rules="[rules.logo]"
                    show-size
                    small-chips
                    prepend-icon="mdi-image"
                    accept="image/png, image/jpeg, image/jpg"
                    label="Logo"
                  ></v-file-input
                ></v-col>
              </v-row>
            </v-card-text>
            <CardSubheader icon="mdi-palette" title="Brand Colors" />

            <v-card-text>
              <v-row>
                <v-col cols="12" md="3" sm="6">
                  <label>Primary Color</label>
                  <v-color-picker v-model="object.primary_color" dot-size="15" mode="hexa"></v-color-picker>
                </v-col>

                <v-col cols="12" md="3" sm="6">
                  <label>Secondary Color</label>
                  <v-color-picker v-model="object.secondary_color" dot-size="15" mode="hexa"></v-color-picker>
                </v-col>

                <v-divider vertical></v-divider>

                <v-col cols="12" md="3" sm="6">
                  <label>Primary Button Color</label>
                  <v-color-picker v-model="object.primary_btn_color" dot-size="15" mode="hexa"></v-color-picker>
                </v-col>

                <v-col cols="12" md="3" sm="6">
                  <label>Secondary Button Color</label>
                  <v-color-picker v-model="object.secondary_btn_color" dot-size="15" mode="hexa"></v-color-picker>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>

          <v-tab-item value="events">
            <EventList :company="object" />
          </v-tab-item>

          <v-tab-item value="codes">
            <QRCodeList :company="object" />
          </v-tab-item>
        </v-tabs-items>

        <v-divider></v-divider>
        <v-card-actions
          ><v-spacer></v-spacer>
          <div class="caption">
            Company ID <strong>{{ object.pk }}</strong>
          </div></v-card-actions
        >
      </v-card>
    </v-form>

    <DeleteConfirmation @confirm-delete="onConfirmDeleteCompany" />
  </div>
</template>

<script>
import { localDatetime, hasPermission } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { DeleteConfirmation, FormHeader, CardSubheader } from '@/spaday/components'
import EventList from '@/views/company/components/EventList'
import QRCodeList from '@/views/company/components/QRCodeList'

export default {
  components: {
    DeleteConfirmation,
    FormHeader,
    CardSubheader,
    EventList,
    QRCodeList,
  },
  data: () => ({
    tabs: [
      {
        text: 'General Info',
        icon: 'mdi-information',
        href: '#general',
      },
      {
        text: 'Events',
        icon: 'mdi-folder-multiple',
        href: '#events',
      },
      {
        text: 'QR Codes',
        icon: 'mdi-map-marker-multiple',
        href: '#codes',
      },
    ],
    rules: {
      required: (value) => !!value || 'Required.',

      logo: (value) => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      object: (state) => state.companies.object,
    }),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        return this.$route.query.tab
      },
    },
  },
  mounted() {
    this.getCompany(this.$route.params.pk)
  },
  beforeDestroy() {
    this.clearCompany()
  },
  methods: {
    hasPermission,
    localDatetime,
    ...mapActions('companies', {
      getCompany: 'get',
      updateCompany: 'update',
      preDeleteCompany: 'preDelete',
      deleteCompany: 'delete',
      clearCompany: 'clear',
    }),
    submit() {
      if (!this.$refs.form.validate()) return

      // Submmitting object as form data to allow multipart/form-data
      let formData = new FormData()
      Object.keys(this.object).forEach((key) => {
        if (this.object[key] !== null) {
          formData.append(key, this.object[key])
        }
      })

      this.updateCompany({ id: this.object.pk, data: this.object }).then(() => {
        this.$router.go(-1)
      })
    },

    onConfirmDeleteCompany(object) {
      this.deleteCompany(object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
