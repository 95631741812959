<template>
  <div>
    <ListHeader
      title="Users"
      subtitle="Create and manage users."
      btnLabel="New User"
      :route="{ name: 'AddUser' }"
      :canAdd="hasPermission('users.add_user')"
    />

    <v-card>
      <v-card-title class="text--primary">
        <SearchField label="Search Users..." @search="updateSearch" />
      </v-card-title>
      <v-data-table
        v-model="userTable.selected"
        item-key="email"
        loading-text="Loading. Please wait..."
        :loading="users.loading"
        :items="users.items"
        :headers="userTable.headers"
        :search="userTable.search"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          'show-current-page': true,
        }"
        :options.sync="userTable.options"
        :server-items-length="userTable.totalItems"
        @update:options="getPaginatedUsers"
      >
        <template v-slot:item.is_superuser="{ item }">
          <RowCheckmark :bool="item.is_superuser" />
        </template>
        <template v-slot:item.is_staff="{ item }">
          <RowCheckmark :bool="item.is_staff" />
        </template>
        <template v-slot:item.last_login="{ item }">
          <span v-if="item.last_login">
            {{ localDatetime(item.last_login) }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <RowActions :actions="userTable.actions" @edit="editUser(item)" @confirm-delete="preDeleteUser(item)" />
        </template>
      </v-data-table>
    </v-card>

    <DeleteConfirmation @confirm-delete="deleteUser" />
  </div>
</template>

<script>
import { localDatetime, hasPermission, getPaginationParams } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { DeleteConfirmation, ListHeader, RowActions, RowCheckmark, SearchField } from '@/spaday/components'

export default {
  components: {
    RowActions,
    DeleteConfirmation,
    RowCheckmark,
    ListHeader,
    SearchField,
  },
  data: () => ({
    userTable: {
      options: {},
      totalItems: 0,
      search: '',
      selected: [],
      headers: [
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'Staff', value: 'is_staff' },
        { text: 'Superuser', value: 'is_superuser' },
        { text: 'Last Login', value: 'last_login' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actions: [
        {
          text: 'Edit User',
          icon: 'mdi-pencil',
          color: 'primary',
          permissions: ['users.change_user'],
          emit: 'edit',
        },
        {
          text: 'Delete User',
          icon: 'mdi-trash-can-outline',
          color: 'error',
          permissions: ['users.delete_user'],
          emit: 'confirm-delete',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      users: (state) => state.users.all,
    }),
  },
  mounted() {
    // this.getUsers()
  },
  methods: {
    hasPermission,
    localDatetime,
    getPaginationParams,
    ...mapActions('users', {
      getUsers: 'getAll',
      preDeleteUser: 'preDelete',
      deleteUser: 'delete',
    }),
    editUser(item) {
      this.$router.push({ name: 'ChangeUser', params: { pk: item.pk } })
    },

    /** Pagination handlers **/
    getPaginatedUsers() {
      const params = this.getPaginationParams(this.userTable.options, this.userTable.search)
      this.getUsers(params).then((data) => {
        this.userTable.totalPages = data.num_pages
        this.userTable.totalItems = data.num_results
      })
    },
    updateSearch(value) {
      this.userTable.search = value
      this.getPaginatedUsers()
    },
  },
}
</script>
