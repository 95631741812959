<template>
  <div>
    <FormHeader
      title="View Scan"
      :subtitle="`${object.ip_address} - ${object.qr_code}`"
      btnLabel="Update Scan"
      :canDelete="hasPermission('core.delete_scan')"
      @delete="preDeleteScan(object)"
      :deleteLoading="$store.state.scans.delete.loading"
    />

    <v-form ref="form" readonly>
      <v-card class="mb-4">
        <v-toolbar dark flat dense color="primary">
          <v-icon left>mdi-information-variant</v-icon>
          <v-toolbar-title> General Info </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field :value="object.ip_address" label="IP Address"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <label>IP Data</label>
              <vue-json-pretty :data="object.ip_data" :showDoubleQuotes="false" :showLine="false" class="mb-8">
              </vue-json-pretty>
            </v-col>
            <v-col cols="12" md="6">
              <label>User Agent Data</label>
              <vue-json-pretty :data="object.ua_data" :showDoubleQuotes="false" :showLine="false"> </vue-json-pretty>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          ><v-spacer></v-spacer>
          <div class="caption">
            Scan ID <strong>{{ object.pk }}</strong>
          </div></v-card-actions
        >
      </v-card>
    </v-form>

    <DeleteConfirmation @confirm-delete="deleteScan" />
  </div>
</template>

<script>
import { localDatetime, hasPermission } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { DeleteConfirmation, FormHeader } from '@/spaday/components'
import VueJsonPretty from 'vue-json-pretty'

import 'vue-json-pretty/lib/styles.css'
import 'highlight.js/styles/default.css'

export default {
  components: {
    DeleteConfirmation,
    FormHeader,
    VueJsonPretty,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      object: (state) => state.scans.object,
    }),
  },
  mounted() {
    this.getScan(this.$route.params.pk)
  },
  beforeDestroy() {
    this.clearScan()
  },
  methods: {
    hasPermission,
    localDatetime,
    ...mapActions('scans', {
      getScan: 'get',
      preDeleteScan: 'preDelete',
      deleteScan: 'delete',
      clearScan: 'clear',
    }),
  },
}
</script>
