<template>
  <div>
    <v-card-title>
      <v-text-field
        v-model="table.search"
        prepend-inner-icon="mdi-magnify"
        label="Search QR Codes..."
        outlined
        hide-details
        dense
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        v-if="hasPermission('core.add_qrcode')"
        color="secondary"
        :to="{ name: 'AddQRCode', query: { company: event.company } }"
      >
        <v-icon left>mdi-plus</v-icon> New QR Code
      </v-btn>
    </v-card-title>
    <v-data-table
      v-model="table.selected"
      :show-select="false"
      item-key="pk"
      loading-text="Loading. Please wait..."
      :loading="qrcodes.loading"
      :items="qrcodes.items"
      :headers="table.headers"
      :search="table.search"
    >
      <template v-slot:item.created_at="{ item }">
        {{ localDatetime(item.created_at) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <RowActions
          :actions="table.actions"
          @edit="editQRCode(item)"
          @confirm-delete="preDeleteQRCode(item)"
          @visit-url="visitQRCodeURL(item)"
          @view-qrcode-stats="viewQRCodeStats(item)"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { localDatetime, hasPermission } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { RowActions } from '@/spaday/components'

export default {
  components: {
    RowActions,
  },
  props: {
    event: { type: Object },
  },
  data: () => ({
    table: {
      search: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Scans', value: 'total_scans' },
        { text: 'Redirect URL', value: 'redirect_url' },
        { text: 'Event', value: 'event_name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actions: [
        {
          text: 'Edit QR Code',
          icon: 'mdi-pencil',
          color: 'primary',
          permissions: ['core.change_qrcode'],
          emit: 'edit',
        },
        // {
        //   text: 'Delete QR Code',
        //   icon: 'mdi-trash-can',
        //   color: 'error',
        //   permissions: ['core.delete_qrcode'],
        //   emit: 'confirm-delete',
        // },
        {
          divider: true,
        },
        {
          text: 'Test Redirect URL',
          icon: 'mdi-link-variant',
          color: 'warning',
          permissions: ['core.view_qrcode'],
          emit: 'visit-url',
        },
        {
          text: 'View Stats',
          icon: 'mdi-chart-bar',
          color: 'success',
          permissions: ['core.view_scan'],
          emit: 'view-qrcode-stats',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      qrcodes: (state) => state.events.qrcodes,
    }),
  },
  mounted() {
    this.getEventQRCodes(this.$route.params.pk)
  },
  beforeDestroy() {},
  methods: {
    hasPermission,
    localDatetime,
    ...mapActions('qrcodes', {
      preDeleteQRCode: 'preDelete',
      deleteQRCode: 'delete',
    }),
    ...mapActions('events', {
      getEventQRCodes: 'getQRCodes',
    }),
    editQRCode(item) {
      this.$router.push({ name: 'ChangeQRCode', params: { pk: item.pk } })
    },
    viewQRCodeStats(item) {
      this.$router.push({ name: 'StatsQRCode', params: { pk: item.pk } })
    },
    visitQRCodeURL(item) {
      window.open(item.absolute_url, '_blank')
    },
  },
}
</script>
