<template>
  <div>
    <v-card class="mb-4" color="transparent">
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col>
            <v-card-title class="text--primary">QR Code Stats</v-card-title>
            <!-- <v-card-subtitle
              ><strong>{{ object.name }}</strong> - {{ object.code }}</v-card-subtitle
            > -->
          </v-col>
          <v-col class="text-right">
            <v-btn class="ma-2" small fab depressed icon outlined @click="$router.go(-1)"
              ><v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <v-btn @click="exportPDF()" color="info" x-large><v-icon left>mdi-file-download</v-icon>Export PDF </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-text>
        <v-row align="center">
          <v-col>
            <div><strong>Company:</strong> {{ object.company_name }}</div>
            <div><strong>Event:</strong> {{ object.event_name }}</div>
            <div><strong>Internal Name:</strong> {{ object.name }}</div>
            <div><strong>QR Code:</strong> {{ object.code }}</div>
            <div><strong>Redirect URL:</strong> {{ object.redirect_url }}</div>
            <div><strong>Created:</strong> {{ localDatetime(object.created_at) }}</div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col v-if="object.image" class="text-center">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-img class="ma-auto" max-height="150" max-width="150" :src="object.image">
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="#000" opacity=".75">
                      <v-btn color="secondary" @click="downloadWithAxios(object.image)"
                        ><v-icon left>mdi-file-download</v-icon> Download</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </template></v-hover
            >
          </v-col>
          <v-divider vertical></v-divider>
          <v-col v-if="stats.scan_data">
            <v-row align="center" justify="center">
              <v-col class="text-center"
                ><div class="text-h6 text--secondary">Total Scans</div>
                <div class="text-h2 text--primary">
                  <strong>
                    <AnimatedInteger :value="stats.scan_data.total_scans" :formatter="formatNumber" />
                  </strong></div
              ></v-col>
              <v-col class="text-center"
                ><div class="text-h6 text--secondary">Unique Scans</div>
                <div class="text-h2 text--primary">
                  <strong>
                    <AnimatedInteger :value="stats.scan_data.unique_scans" :formatter="formatNumber" />
                  </strong></div
              ></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-toolbar dark flat dense color="primary">
        <!-- <v-icon left></v-icon> -->
        <v-toolbar-title>Number of QR Code Scans</v-toolbar-title>
      </v-toolbar>
      <!-- <v-card-title>Number of QR Code Scans</v-card-title> -->
      <v-card-text>
        <GenericChart refs="printMe" :chartdata="chartData" />
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12" md="6" class="d-flex">
        <StatTableCard title="By Operating System" :items="stats.os_data" :headers="tableHeaders" />
      </v-col>

      <v-col cols="12" md="6" class="d-flex">
        <StatTableCard title="By Platform" :items="stats.platform_data" :headers="tableHeaders" />
      </v-col>

      <v-col cols="12" md="6" class="d-flex">
        <StatTableCard title="By Browser" :items="stats.browser_data" :headers="tableHeaders" />
      </v-col>

      <v-col cols="12" md="6" class="d-flex">
        <StatTableCard title="By Device" :items="stats.device_data" :headers="tableHeaders" />
      </v-col>

      <v-col cols="12" md="4" class="d-flex">
        <StatTableCard title="By City" :items="stats.city_data" :headers="tableHeaders" />
      </v-col>

      <v-col cols="12" md="4" class="d-flex">
        <StatTableCard title="By State" :items="stats.state_data" :headers="tableHeaders" />
      </v-col>

      <v-col cols="12" md="4" class="d-flex">
        <StatTableCard title="By Country" :items="stats.country_data" :headers="tableHeaders" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { localDatetime } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { StatTableCard, GenericChart } from '@/spaday/components'
import { downloadWithAxios } from '@/spaday/helpers'
import { AnimatedInteger } from '@/spaday/components'

export default {
  components: {
    StatTableCard,
    GenericChart,
    AnimatedInteger,
  },
  data: () => ({
    tableHeaders: [
      { text: 'Name', value: 'label', align: 'left' },
      { text: 'Scans', value: 'value', align: 'left' },
      { text: 'Percentage', value: 'percentage', align: 'left' },
    ],
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      object: (state) => state.qrcodes.object,
      stats: (state) => state.qrcodes.stats,
    }),
    chartData() {
      return {
        type: 'line',
        data: {
          labels: this.stats.chart_data.labels,
          datasets: [
            {
              label: 'Scans',
              data: this.stats.chart_data.values,
              barPercentage: 0.5,
              backgroundColor: 'rgba(105, 103,206,.5)',
              borderColor: 'rgb(105, 103,206)',
              borderWidth: 2,
              fill: false,
            },
          ],
        },
        options: {
          aspectRatio: 4 / 1,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      }
    },
  },
  mounted() {
    this.getScan(this.$route.params.pk)
    this.getStats(this.$route.params.pk)
  },
  methods: {
    // hasPermission,
    downloadWithAxios,
    localDatetime,
    ...mapActions('qrcodes', {
      getScan: 'get',
      getStats: 'getStats',
    }),
    submit() {
      if (!this.$refs.form.validate()) return
    },
    exportPDF() {
      EventBus.$emit('show-generic-dialog', {
        title: 'Oops!',
        message:
          'This feature is not implemented yet. <i>We are still working on it...</i> <img width="100%" src="https://media.giphy.com/media/fQZX2aoRC1Tqw/giphy.gif" class="mt-4"/>',
        color: 'warning',
        icon: 'mdi-alert',
      })
    },
    formatNumber(value) {
      if (isNaN(value)) {
        return '--'
      }
      return new Intl.NumberFormat('en-US').format(value)
    },
  },
}
</script>
