import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VueCookies from 'vue-cookies'

import vuetify from '@/plugins/vuetify'

// import LogRocket from 'logrocket'
// LogRocket.init('nm1oqt/test')

Vue.use(VueCookies)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    this.$store.dispatch('preferences/initialiseStore')
  },
}).$mount('#app')
