import { List, View } from '@/views/scan'

const routes = [
  {
    path: '/scan',
    name: 'Scan',
    component: List,
    meta: {
      public: false,
      permissions: ['core.view_scan'],
    },
  },
  //   {
  //     path: '/scan/add',
  //     name: 'AddScan',
  //     component: Create,
  //     meta: {
  //       public: false,
  //       permissions: ['core.add_scan'],
  //     },
  //   },
  //   {
  //     path: '/scan/:pk/change',
  //     name: 'ChangeScan',
  //     component: Update,
  //     meta: {
  //       public: false,
  //       permissions: ['core.change_scan'],
  //     },
  {
    path: '/scan/:pk/view',
    name: 'ViewScan',
    component: View,
    meta: {
      public: false,
      permissions: ['core.view_scan'],
    },
  },
]

export default routes
