<template>
  <div>
    <ListHeader title="Audit Log" subtitle="View log entries." />

    <v-card>
      <v-card-title class="text--primary">
        <SearchField label="Search Log Entries..." @search="updateSearch" />
      </v-card-title>
      <v-data-table
        v-model="logTable.selected"
        item-key="pk"
        loading-text="Loading. Please wait..."
        :loading="auditlog.loading"
        :items="auditlog.items"
        :headers="logTable.headers"
        :search="logTable.search"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          'show-current-page': true,
        }"
        :options.sync="logTable.options"
        :server-items-length="logTable.totalItems"
        @update:options="getPaginatedLogs"
      >
        <template v-slot:item.action="{ item }">
          <v-chip class="action-chip" small :color="actionColor(item.action)">{{ item.action }}</v-chip>
        </template>
        <template v-slot:item.model="{ item }">
          {{ upperCaseFirst(item.model) }}
        </template>
        <template v-slot:item.timestamp="{ item }">
          {{ localDatetime(item.timestamp) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <RowActions :actions="logTable.actions" @edit="editLog(item)" @confirm-delete="preDeleteLog(item)" />
        </template>
      </v-data-table>
    </v-card>

    <DeleteConfirmation @confirm-delete="deleteLog" />
  </div>
</template>

<script>
import { localDatetime, hasPermission, upperCaseFirst, getPaginationParams } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { ListHeader, RowActions, DeleteConfirmation, SearchField } from '@/spaday/components'

export default {
  components: {
    RowActions,
    DeleteConfirmation,
    ListHeader,
    SearchField,
  },
  data: () => ({
    logTable: {
      options: {},
      totalItems: 0,
      search: '',
      selected: [],
      headers: [
        { text: 'App', value: 'app' },
        { text: 'Model', value: 'model' },
        { text: 'Resource', value: 'object_repr' },
        { text: 'Action', value: 'action' },
        { text: 'Changes', value: 'changes_summary', sortable: false },
        { text: 'User', value: 'user' },
        { text: 'Remote Address', value: 'remote_addr' },
        { text: 'Timestamp', value: 'timestamp' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actions: [
        {
          text: 'View Log Entry',
          icon: 'mdi-eye',
          color: 'primary',
          permissions: ['auditlog.change_logentry'],
          emit: 'edit',
        },
        {
          text: 'Delete Log Entry',
          icon: 'mdi-trash-can-outline',
          color: 'error',
          permissions: ['auditlog.delete_logentry'],
          emit: 'confirm-delete',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      auditlog: (state) => state.auditlog.all,
    }),
  },
  mounted() {
    // this.getLogs()
  },
  methods: {
    hasPermission,
    localDatetime,
    getPaginationParams,
    upperCaseFirst,
    ...mapActions('auditlog', {
      getLogs: 'getAll',
      preDeleteLog: 'preDelete',
      deleteLog: 'delete',
    }),
    editLog(item) {
      this.$router.push({ name: 'ChangeAuditLog', params: { pk: item.pk } })
    },
    actionColor(action) {
      if (action === 'Delete') {
        return 'error'
      } else if (action === 'Update') {
        return 'warning'
      }
      return 'success'
    },

    /** Pagination handlers **/
    getPaginatedLogs() {
      const params = this.getPaginationParams(this.logTable.options, this.logTable.search)
      this.getLogs(params).then((data) => {
        this.logTable.totalPages = data.num_pages
        this.logTable.totalItems = data.num_results
      })
    },
    updateSearch(value) {
      this.logTable.search = value
      this.getPaginatedLogs()
    },
  },
}
</script>

<style scoped>
.action-chip {
  display: block;
  text-align: center;
}
</style>
