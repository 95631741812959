<template>
  <div>
    <FormHeader
      title="Update QR Code"
      :subtitle="object.name"
      btnLabel="Update QR Code"
      :canChange="hasPermission('core.change_qrcode')"
      :canDelete="hasPermission('core.delete_qrcode')"
      @submit="submit"
      @delete="preDeleteQRCode(object)"
      :deleteLoading="$store.state.qrcodes.delete.loading"
      :saveLoading="$store.state.qrcodes.object.loading"
    />

    <v-form ref="form" :readonly="!hasPermission('core.change_qrcode')">
      <v-card class="mb-4">
        <v-toolbar dark flat dense color="primary">
          <v-icon left>mdi-information-variant</v-icon>
          <v-toolbar-title> General Info </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="object.name" label="Name" :rules="[rules.required]"></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field v-model="object.redirect_url" label="Redirect URL" :rules="[rules.required]">
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                label="Event"
                v-model="object.event"
                :items="events.items"
                :loading="events.loading"
                item-value="pk"
                item-text="name"
                hide-no-data
              ></v-select>
            </v-col>

            <!-- <v-col cols="12" md="6">
              <v-file-input
                disabled
                v-model="object.logo"
                :rules="[rules.value]"
                show-size
                small-chips
                prepend-icon="mdi-image"
                accept="image/png, image/jpeg, image/jpg"
                label="Logo"
              >
              </v-file-input>
            </v-col> -->

            <v-col cols="12" md="6">
              <v-text-field v-model="object.code" label="Code" disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field v-model="object.absolute_url" label="Absolute URL" readonly></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-img max-height="400" max-width="400" :src="object.image" class="mb-4">
                    <v-fade-transition>
                      <v-overlay v-if="hover" absolute color="#000" opacity=".75">
                        <v-btn
                          color="secondary"
                          x-large
                          @click="downloadWithAxios(object.image, `${object.event_name}_${object.name}.svg`)"
                        >
                          <v-icon left>mdi-file-download</v-icon> Download QR Code
                        </v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-img>
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="caption">
            QR Code ID <strong>{{ object.pk }}</strong>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>

    <DeleteConfirmation @confirm-delete="onConfirmDeleteQRCode" />
  </div>
</template>

<script>
import { localDatetime, hasPermission } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { DeleteConfirmation, FormHeader } from '@/spaday/components'
import { downloadWithAxios } from '@/spaday/helpers'

export default {
  components: {
    DeleteConfirmation,
    FormHeader,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required.',

      value: (value) => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      object: (state) => state.qrcodes.object,
      events: (state) => state.events.combobox,
    }),
  },
  mounted() {
    this.getQRCode(this.$route.params.pk)
    this.getEvents()
    // if (this.object.company) {
    //   this.getCompanyEvents(parseInt(this.object.company))
    // } else {
    //   this.getEvents()
    // }
  },
  beforeDestroy() {
    this.clearQRCode()
  },
  methods: {
    hasPermission,
    localDatetime,
    downloadWithAxios,
    ...mapActions('qrcodes', {
      getQRCode: 'get',
      updateQRCode: 'update',
      preDeleteQRCode: 'preDelete',
      deleteQRCode: 'delete',
      clearQRCode: 'clear',
    }),
    ...mapActions('events', {
      getEvents: 'getCombobox',
    }),
    // ...mapActions('companies', {
    //   getCompanyEvents: 'getEvents',
    // }),
    submit() {
      if (!this.$refs.form.validate()) return

      // Submmitting object as form data to allow multipart/form-data
      let formData = new FormData()
      Object.keys(this.object).forEach((key) => {
        if (this.object[key] !== null) {
          formData.append(key, this.object[key])
        }
      })

      this.updateQRCode({ id: this.object.pk, data: this.object }).then(() => {
        this.$router.go(-1)
      })
    },
    onConfirmDeleteQRCode(object) {
      this.deleteQRCode(object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
