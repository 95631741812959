import http from '@/spaday/http-common'

class CompanyService {
  /** Base CRUD REST calls **/
  getAll(params = {}) {
    return http.get('/companies/', { params })
  }

  get(pk) {
    return http.get(`/companies/${pk}/`)
  }

  create(data) {
    return http.post('/companies/', data)
  }

  update(pk, data) {
    return http.put(`/companies/${pk}/`, data)
  }

  preDelete(pk) {
    return http.get(`/companies/${pk}/confirm-delete/`)
  }

  delete(pk) {
    return http.delete(`/companies/${pk}/`)
  }

  /** Specialized REST calls **/

  combobox() {
    return http.get(`/companies/combobox/`)
  }

  events(pk) {
    return http.get(`/companies/${pk}/events/`)
  }

  qrcodes(pk) {
    return http.get(`/companies/${pk}/qrcodes/`)
  }
}

export default new CompanyService()
