import Vue from 'vue'

// https://www.digitalocean.com/community/tutorials/vuejs-global-event-bus
export const EventBus = new Vue()

// Example Usage:
// EventBus.$emit('clicked, 1)

// const clickHandler = function(clickCount) {
//     console.log(`The button has been clicked ${clickCount} times!`)
// }

// EventBus.$on('clicked', clickHandler);
