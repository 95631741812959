import http from '@/spaday/http-common'

class ScanService {
  /** Base CRUD REST calls **/
  getAll(params = {}) {
    return http.get('/scans/', { params })
  }

  get(pk) {
    return http.get(`/scans/${pk}/`)
  }

  create(data) {
    return http.post('/scans/', data)
  }

  update(pk, data) {
    return http.put(`/scans/${pk}/`, data)
  }

  preDelete(pk) {
    return http.get(`/scans/${pk}/confirm-delete/`)
  }

  delete(pk) {
    return http.delete(`/scans/${pk}/`)
  }

  /** Specialized REST calls **/
}

export default new ScanService()
