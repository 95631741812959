<template>
  <div>
    <v-card-title>
      <v-text-field
        v-model="table.search"
        prepend-inner-icon="mdi-magnify"
        label="Search Events..."
        outlined
        hide-details
        dense
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-btn
        v-if="hasPermission('core.add_event')"
        color="secondary"
        :to="{ name: 'AddEvent', query: { company: company.pk } }"
      >
        <v-icon left>mdi-plus</v-icon> New Event
      </v-btn>
    </v-card-title>
    <v-data-table
      v-model="table.selected"
      :show-select="false"
      item-key="pk"
      loading-text="Loading. Please wait..."
      :loading="events.loading"
      :items="events.items"
      :headers="table.headers"
      :search="table.search"
    >
      <template v-slot:item.created_at="{ item }">
        {{ localDatetime(item.created_at) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <RowActions
          :actions="table.actions"
          @edit="editEvent(item)"
          @delete="preDeleteEvent(item)"
          @view-event-stats="viewEventStats(item)"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { localDatetime, hasPermission } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { RowActions } from '@/spaday/components'

export default {
  components: {
    RowActions,
  },
  props: {
    company: { type: Object },
  },
  data: () => ({
    table: {
      search: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'QR Codes', value: 'total_codes' },
        { text: 'Created', value: 'created_at' },
        { text: 'Actions', value: 'actions' },
      ],
      actions: [
        {
          text: 'Edit Event',
          icon: 'mdi-pencil',
          color: 'primary',
          permissions: ['core.change_event'],
          emit: 'edit',
        },
        // {
        //   text: 'Delete Event',
        //   icon: 'mdi-trash-can-outline',
        //   color: 'error',
        //   permissions: ['core.delete_event'],
        //   emit: 'delete',
        // },
        {
          divider: true,
        },
        {
          text: 'View Stats',
          icon: 'mdi-chart-bar',
          color: 'success',
          permissions: ['core.view_event'],
          emit: 'view-event-stats',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      events: (state) => state.companies.events,
    }),
  },
  mounted() {
    this.getCompanyEvents(this.$route.params.pk)
  },
  beforeDestroy() {},
  methods: {
    hasPermission,
    localDatetime,
    ...mapActions('events', {
      preDeleteEvent: 'preDelete',
      deleteEvent: 'delete',
    }),
    ...mapActions('companies', {
      getCompanyEvents: 'getEvents',
    }),

    editEvent(item) {
      this.$router.push({
        name: 'ChangeEvent',
        params: { pk: item.pk },
      })
    },
    viewEventStats(item) {
      this.$router.push({ name: 'StatsEvent', params: { pk: item.pk } })
    },
  },
}
</script>
