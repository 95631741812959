<template>
  <div>
    <ListHeader
      title="Companies"
      subtitle="Create and manage companies."
      btnLabel="New Company"
      :route="{ name: 'AddCompany' }"
      :canAdd="hasPermission('core.add_company')"
    />

    <v-card>
      <v-card-title class="text--primary">
        <SearchField label="Search Companies..." @search="updateSearch" />
      </v-card-title>
      <v-data-table
        v-model="companyTable.selected"
        :show-select="false"
        item-key="pk"
        loading-text="Loading. Please wait..."
        :loading="companies.loading"
        :items="companies.items"
        :headers="companyTable.headers"
        :search="companyTable.search"
        :footer-props="{
          'items-per-page-options': [15, 25, 50, 100],
          'show-current-page': true,
        }"
        :options.sync="companyTable.options"
        :server-items-length="companyTable.totalItems"
        @update:options="getPaginatedCompanies"
      >
        <template v-slot:item.actions="{ item }">
          <RowActions
            :actions="companyTable.actions"
            @edit="editCompany(item)"
            @confirm-delete="preDeleteCompany(item)"
          />
        </template>
      </v-data-table>
    </v-card>

    <DeleteConfirmation @confirm-delete="deleteCompany" />
  </div>
</template>

<script>
import { localDatetime, hasPermission, getPaginationParams } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { DeleteConfirmation, ListHeader, RowActions, SearchField } from '@/spaday/components'

export default {
  components: {
    RowActions,
    DeleteConfirmation,
    ListHeader,
    SearchField,
  },
  data: () => ({
    companyTable: {
      options: {},
      totalItems: 0,
      search: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Events', value: 'num_events', sortable: false },
        { text: 'QR Codes', value: 'num_codes', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actions: [
        {
          text: 'Edit Company',
          icon: 'mdi-pencil',
          color: 'primary',
          permissions: ['core.change_company'],
          emit: 'edit',
        },
        {
          text: 'Delete Company',
          icon: 'mdi-trash-can',
          color: 'error',
          permissions: ['core.delete_company'],
          emit: 'confirm-delete',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      companies: (state) => state.companies.all,
    }),
  },
  mounted() {
    // this.getCompanies()
  },
  methods: {
    hasPermission,
    localDatetime,
    getPaginationParams,
    ...mapActions('companies', {
      getCompanies: 'getAll',
      preDeleteCompany: 'preDelete',
      deleteCompany: 'delete',
    }),
    editCompany(item) {
      this.$router.push({ name: 'ChangeCompany', params: { pk: item.pk } })
    },

    /** Pagination handlers **/
    getPaginatedCompanies() {
      const params = this.getPaginationParams(this.companyTable.options, this.companyTable.search)
      this.getCompanies(params).then((data) => {
        this.companyTable.totalPages = data.num_pages
        this.companyTable.totalItems = data.num_results
      })
    },
    updateSearch(value) {
      this.companyTable.search = value
      this.getPaginatedCompanies()
    },
  },
}
</script>
